import React from "react"
import { Modal, Upload } from "antd"
import Icon, { StarFilled } from "@ant-design/icons"
import CancelIcon from "../../../Icons/Cancel.icons"
import "./CSVModal.styles.scss"
import PropTypes from "prop-types"
import axios from "axios"
import { useHistory } from "react-router"

const CSVModal = ({ visible, onClose }) => {
  const history = useHistory()
  const uploadCSV = async (file) => {
    const formData = new FormData()
    formData.append("file", file, file.name)
    const { data } = await axios.request({
      url: "/v1/people/import",
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
      method: "POST",
    })
    history.push(`/account/settings/csv/${data.import.id}`, {
      name: file.name,
      id: data.import.id,
    })
  }

  return (
    <Modal
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onClose}
      width={623}
      closeIcon={<Icon component={CancelIcon} className="CSVModal_CloseIcon" />}
      wrapClassName="CSVModal"
    >
      <div>
        <div className="CSVModal_Header">
          <span>Import a CSV Spreadsheet</span>
        </div>
        <div className="CSVModal_Body">
          <p>
            You can bring in contacts from other sources, like Linkedin or other
            CRMs, or that now-obsolete spreadsheet that you manually updated every
            time (gross!).
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/files/sample_relatable_csv.csv`}
              className={"CSVModal_Body__underline"}
            >
              <StarFilled className={"CSVModal_Body_Icon"} /> Want to grab a sample
              file that you can add your data into? Click here
            </a>
          </p>
          <p>
            Once you upload your file (remember to save it as a CSV file) below, you
            will then be able to map the columns in the spreadsheet to fields in your
            Relatable contacts, and choose if you want everyone added to a specific
            sphere, topic, or expertise.
          </p>
          <p>
            If the email address matches an existing person in Relatable, we'll
            update that one. Don't worry too much about duplicates, we're great at
            finding them!
          </p>
        </div>

        <div className="CSVModal_Footer">
          <Upload accept={".csv"} action={(file) => uploadCSV(file)}>
            <button className={"CSVModal_Footer_Button"}>
              Click here to select a CSV file
            </button>
          </Upload>
        </div>
      </div>
    </Modal>
  )
}

CSVModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
export default CSVModal
